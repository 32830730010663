import React, { useState } from 'react'
import ExpandablePanel from 'components/ExpandablePanel'
import styled, { css } from 'styled-components'
import { useDispatch } from 'react-redux'
import Input from 'components/Input'
import Select from 'components/Select'
import Button from 'components/Button'
import { isNilOrEmpty, isNotNilOrEmpty } from 'utils/ramda'
import StudentTeachersList from 'modules/students/components/StudentTeachersList'
import AddStudentTeacherModal from 'modules/students/components/AddStudentTeacherModal'
import { addStudent } from 'services/StudentsService'
import { getActiveStudentsRoutine } from 'modules/students/ducks/actions'
import { update } from 'ramda'
import moment from 'moment'
import { DATE_FORMATS } from 'utils/date'
import { STUDENT_ACCOUNTS } from 'utils/student'

const emptyValues = {
  name: '',
  teachers: [],
  email: '',
  phoneNumber: '',
  archived: false,
  removed: false,
  balance: 0,
  account: 'account1'
}

const AddStudentForm = ({ callback }) => {
  const [values, setValues] = useState(emptyValues)
  const dispatch = useDispatch()

  const handleValueChange = (name, value) => {
    setValues(prev => ({ ...prev, [name]: value }))
  }

  const handleSubmit = e => {
    e.preventDefault()
    addStudent({
      ...values,
      name: values.name.trim(),
      email: values.email.trim(),
      phoneNumber: values.phoneNumber.trim(),
      createdAt: moment().format(DATE_FORMATS.withTimeAndSeconds)
    }, () => {
      typeof callback === 'function'
        ? callback()
        : dispatch(getActiveStudentsRoutine())
      setValues(emptyValues)
    })
  }

  const handleAddTeacher = teacherValues => {
    handleValueChange('teachers', [
      ...values.teachers,
      teacherValues
    ])
  }

  const handleEditTeacher = (teacherValues, index) => {
    setValues(prev => ({
      ...prev,
      teachers: update(index, teacherValues, prev.teachers)
    }))
  }

  const handleDeleteTeacher = index => {
    setValues(prev => ({
      ...prev,
      teachers: values.teachers.filter((_, i) => i !== index)
    }))
  }

  return (
    <ExpandablePanel title='Nowy uczeń'>
      <SectionHeader>Dane podstawowe</SectionHeader>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Input
            label='Nazwa ucznia'
            name='name'
            onChange={handleValueChange}
            value={values.name}
          />
          <Input
            label='Numer telefonu'
            name='phoneNumber'
            onChange={handleValueChange}
            value={values.phoneNumber}
          />
          <Input
            label='Email'
            name='email'
            onChange={(name, value) => handleValueChange('email', value.replace(/\s/g, ''))}
            value={values.email}
          />
        </Row>
        <Row>
          <Select
            label='Konto do wpłat'
            name='account'
            onChange={handleValueChange}
            value={values.account}
            options={Object.entries(STUDENT_ACCOUNTS).map(([value, label]) => ({ value, label }))}
          />
        </Row>
        <ListHeaderWrapper>
          <SectionHeader noMargin>Przypisani nauczyciele</SectionHeader>
          <AddStudentTeacherModal onAddTeacher={handleAddTeacher} />
        </ListHeaderWrapper>
        {isNotNilOrEmpty(values.teachers) && (
          <StudentTeachersList
            onEditTeacher={handleEditTeacher}
            onDeleteTeacher={handleDeleteTeacher}
            teachers={values.teachers}
          />
        )}
        <ButtonWrapper>
          <Button
            type='submit'
            color='primary-outline'
            disabled={isNilOrEmpty(values.name)}
          >
            Stwórz ucznia
          </Button>
        </ButtonWrapper>
      </Form>
    </ExpandablePanel>
  )
}

export default AddStudentForm

const Form = styled.form`
  margin-top: 10px;
  width: 100%;
  
  input {
    width: 100%;
  }
`

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;

  button {
    max-width: 200px;
  }
`

const Row = styled.div`
  display: flex;
  width: 100%;
  gap: 20px;
  align-items: center;
  
  ${({ inModal }) => inModal && css`
    flex-direction: column;
    & > div {
      width: 100%;
    }
  `}
`

const SectionHeader = styled.div`
  font-size: 14px;
  text-transform: uppercase;
  margin-top: ${({ noMargin }) => noMargin ? '0' : '30px'};
  color: ${({ theme }) => theme.colors.secondary.main};
`

const ListHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 30px 0 10px;
`
