import { db } from 'firestoreConfig'
import { v4 as uuid } from 'uuid'
import { omit, pathOr, reverse } from 'ramda'
import moment from 'moment'
import { DATE_FORMATS } from 'utils/date'
import { toast } from 'react-hot-toast'
import { updatePreviousDebitItems, updatePreviousTeacherDebitItems } from 'services/LessonsService'
import { parseNumber } from 'utils/numbers'

export const getStudentsList = () => {
  return db
    .collection('lists')
    .doc('students')
    .get()
    .then(async doc => {
      if (doc.exists) {
        const data = doc.data()
        return data.list
      }
    })
    .catch(error => {
      console.error('Error getting document:', error)
      return []
    })
}

export const fetchActiveStudents = () => {
  let students = []
  return db
    .collection('students')
    .where('removed', '!=', true)
    .get()
    .then(querySnapshot => {
      querySnapshot.forEach(doc => {
        students = [...students, doc.data()]
      })
    })
    .then(() => {
      return students
    })
    .catch(error => {
      console.log('Error getting document:', error)
      return []
    })
}


export const fetchArchivedStudents = () => {
  let students = []
  return db
    .collection('students')
    .where('removed', '==', true)
    .get()
    .then(querySnapshot => {
      querySnapshot.forEach(doc => {
        students = [...students, doc.data()]
      })
    })
    .then(() => {
      return students
    })
    .catch(error => {
      console.log('Error getting document:', error)
      return []
    })
}

export const getSingleStudent = (id, setValuesFn) => {
  return db
    .collection('students')
    .doc(id)
    .get()
    .then(async doc => {
      if (doc.exists) {
        const data = await doc.data()
        typeof setValuesFn === 'function' && setValuesFn(data)
        return data
      }
    })
    .catch(error => {
      console.error('Error getting document:', error)
    })
}

export const getSingleStudentLessons = async ({ id, dateFrom, dateTo }) => {
  const snapshot = await db
    .collection('lessons')
    .where('student.id', '==', id)
    .where('date', '>=', dateFrom)
    .where('date', '<=', `${dateTo}T23:59`)
    .get()
  if (snapshot.empty) {
    return []
  } else {
    let data = []
    snapshot.forEach(doc => {
      data = [...data, doc.data()]
    })
    return data
  }
}

export const getSingleStudentDebitItems = async ({ id, dateFrom, dateTo }) => {
  const snapshot = await db
    .collection('debit')
    .where('studentId', '==', id)
    .where('date', '>=', dateFrom)
    .where('date', '<=', `${dateTo}T23:59`)
    .get()
  if (snapshot.empty) {
    return []
  } else {
    let data = []
    snapshot.forEach(doc => {
      data = [...data, doc.data()]
    })
    return data
  }
}

export const addStudent = async (values, callback) => {
  const id = uuid()
  try {
    const docRef = db.collection('students').doc(id)

    const valuesToSet = {
      ...values,
      id
    }

    await docRef.set(valuesToSet)
    typeof callback === 'function' && callback()
  } catch (err) {
    console.error(err)
  }
}

export const updateStudent = ({ student, callback }) => {
  const payload = omit([
    'students',
    'balances',
    'lessons',
    'hasLessonsInPrevMonth',
    'message',
    'title'
  ], student)
  const objectRef = db.collection('students').doc(student.id)
  return objectRef.update(payload)
    .then(async () => {
      typeof callback === 'function' && callback()
    })
    .catch(function(error) {
      console.error('Error updating document: ', error)
    })
}

// export const removeStudent = ({ student, callback }) => {
//   updateStudent({
//     student: {
//       ...student,
//       removed: true,
//       teachers: []
//     },
//     callback
//   })
// }

export const removeStudent = ({ student, callback }) => {
  const studentRef = db.collection('students').doc(student.id)
  return studentRef.delete()
    .then(() => {
      toast.success('Uczeń został usunięty')
      typeof callback === 'function' && callback()
    })
    .catch(function(error) {
      toast.error('Wystąpił błąd podczas usuwania ucznia')
      console.error('Error removing document: ', error)
    })
}

export const markStudentToBeDeleted = ({ value, student, callback }) => {
  updateStudent({
    student: {
      ...student,
      toBeDeleted: value
    },
    callback
  })
}

export const restoreStudent = ({ student, callback }) => {
  updateStudent({
    student: {
      ...student,
      removed: false
    },
    callback
  })
}

export const addStudentPayment = async ({ values, callback }) => {
  const { debitPayload, studentPayload } = values

  const debitRef = db.collection('debit').doc(debitPayload.id)
  const studentRef = db.collection('students').doc(studentPayload.id)
  const prevSnapshot = await db
    .collection('debit')
    .where('studentId', '==', debitPayload.studentId)
    .where('date', '<', debitPayload.date)
    .where('date', '>', moment(debitPayload.date).subtract(6, 'month').format(DATE_FORMATS.inputWithTime))
    .get()

  const prepareStudentDebitItems = new Promise(resolve => {
    let debitsData = []
    let index = 0
    if (!prevSnapshot.empty) {
      prevSnapshot.forEach(doc => {
        debitsData = [...debitsData, doc.data()]
        if (index === prevSnapshot.docs.length - 1) {
          resolve(debitsData)
        }
        index++
      })
    } else {
      return resolve([])
    }
  })

  prepareStudentDebitItems
    .then(async studentDebitItems => {
      const batch = db.batch()
      const sortedStudentDebitItems = reverse(studentDebitItems.sort((a, b) => a.date - b.date))
      const lastBalance = parseNumber(pathOr(0, [0, 'balanceAfter'], sortedStudentDebitItems))

      const fullDebitPayload = {
        ...debitPayload,
        balanceAfter: parseNumber(lastBalance) + parseNumber(debitPayload.value)
      }

      batch.set(debitRef, fullDebitPayload)
      batch.update(studentRef, studentPayload)

      batch.commit()
        .then(() => {
          updatePreviousDebitItems({
            debit: fullDebitPayload,
            callback
          })
          toast.success('Dodano nową wpłatę')
          typeof callback === 'function' && callback()
        })
        .catch((error) => {
          toast.error('Wystąpił błąd podczas dodawania nowej wpłaty')
          console.error("Transakcja nie powiodła się: ", error)
        })
    })
}

export const removeStudentPayment = async ({ values, callback }) => {
  const { debitPayload, studentPayload } = values
  const batch = db.batch()
  const debitRef = db.collection('debit').doc(debitPayload.id)
  const studentRef = db.collection('students').doc(studentPayload.id)

  batch.delete(debitRef)
  batch.update(studentRef, studentPayload)

  batch.commit()
    .then(() => {
      updatePreviousDebitItems({
        debit: debitPayload,
        lessonDifference: parseNumber(debitPayload.value) * -1,
        callback
      })
      toast.success('Usunięto wpłatę')
      typeof callback === 'function' && callback()
    })
    .catch((error) => {
      toast.error('Wystąpił błąd podczas usuwania wpłaty')
      console.error("Transakcja nie powiodła się: ", error)
    })
}

export const updateStudentPayment = async ({ values, callback }) => {
  const { debitPayload, studentPayload, difference } = values
  const batch = db.batch()
  const debitRef = db.collection('debit').doc(debitPayload.id)
  const studentRef = db.collection('students').doc(studentPayload.id)

  batch.update(debitRef, debitPayload)
  batch.update(studentRef, studentPayload)

  batch.commit()
    .then(() => {
      updatePreviousDebitItems({
        debit: debitPayload,
        lessonDifference: difference * -1,
        callback
      })
      toast.success('Zaktualizowano wpłatę')
      typeof callback === 'function' && callback()
    })
    .catch((error) => {
      toast.error('Wystąpił błąd podczas aktualizacji wpłaty')
      console.error("Transakcja nie powiodła się: ", error)
    })
}

export const updateStudentDebitItem = async ({ values, callback }) => {
  const itemRef = await db.collection('debit').doc(values.id)
  itemRef
    .update(values)
    .then(() => {
      callback && callback()
      toast.success('Zapisano zmiany')
    })
}
