import React, { useEffect, useState } from 'react'
import { getDebtors } from 'services/DebtorsService'
import DebtorsByTeacherTable from 'modules/debtors/components/DebtorsByTeacherTable'
import DebtorsFilters from 'modules/debtors/components/DebtorsFilters'
import { isNilOrEmpty, isNotNilOrEmpty } from 'utils/ramda'
import styled from 'styled-components'
import Button from 'components/Button'
import { getCurrentParsedQuery, redirect } from 'utils/navigation'
import PATHS from 'utils/paths'
import { useHistory } from 'react-router-dom'
import Modal from 'components/Modal'
import qs from 'qs'
import { useDispatch, useSelector } from 'react-redux'
import { selectSchoolSubjects } from 'modules/settings/ducks/selectors'
import { fetchSchoolSubjectsRoutine } from 'modules/settings/ducks/actions'

const ALL_VALUE = 'all'

const Debtors = () => {
  const history = useHistory()
  const { location: { search } } = history
  const queryParams = qs.parse(search, { ignoreQueryPrefix: true })
  const subjects = useSelector(selectSchoolSubjects)

  const dispatch = useDispatch()


  const [teachers, setTeachers] = useState([])
  const [selectedStudents, setSelectedStudents] = useState([])
  const [phoneModalOpen, setPhoneModalOpen] = useState()
  const [filters, setFilters] = useState({
    lessonType: queryParams.lessonType || ALL_VALUE,
    account: queryParams.account || ALL_VALUE,
    subject: queryParams.subject || ALL_VALUE
  })

  console.log({subjects, teachers})

  const toggleStudentSelection = student => () => {
    selectedStudents.some(s => s.id === student.id)
      ? setSelectedStudents(prev => prev.filter(s => s.id !== student.id))
      : setSelectedStudents(prev => [ ...prev,  student ])
  }

  useEffect(() => {
    const { id } = getCurrentParsedQuery()
    const elements = document.querySelectorAll(`[data-id="${id}"]`)
    if (isNotNilOrEmpty(elements[0])) {
      elements[0].scrollIntoView({ block: 'center' })
      elements[0].style.background = '#FCFCBF'
      setTimeout(() => {
        elements[0].style.background = 'transparent'
      }, 1000)
    }
  }, [teachers, search])

  const fetchDebtors = () => {
    getDebtors()
      .then(result => {
        setTeachers(result)
      })
  }

  const handleFetchDebtorsWithFilters = () => {
    const currentSearch = window.location.search
    window.location.href = `${window.location.pathname}${currentSearch}`
  }

  useEffect(() => {
    fetchDebtors()
    dispatch(fetchSchoolSubjectsRoutine())
  }, [])

  const handleClearSelected = () => {
    setSelectedStudents([])
  }

  const handleRedirectToMessage = () => {
    redirect(PATHS.message, selectedStudents)
  }

  const handlePhoneNumbersModalOpen = () => setPhoneModalOpen(true)
  const handlePhoneNumbersModalClose = () => setPhoneModalOpen(false)

  const handleApplyFilters = newFilters => {
    setFilters(newFilters)
    const query = qs.stringify(newFilters, { skipEmptyString: true, skipNull: true })
    history.push({ search: query })
  }

  const filterTeachers = teachers => {
    return teachers.map(teacherData => {
      const filteredStudents = teacherData.students.filter(student => {
        // Sprawdzamy, czy uczeń ma tylko lekcje gotówkowe u danego nauczyciela
        const teacherLessons = student.teachers.filter(t => t.id === teacherData.teacher.id)
        const onlyCashLessons = teacherLessons.every(t => ['classroom_cash', 'away_cash'].includes(t.lessonType))

        // Jeśli wybrany jest konkretny filtr konta i uczeń ma tylko lekcje gotówkowe, nie pokazujemy go
        if (filters.account !== ALL_VALUE && onlyCashLessons) {
          return false
        }

        const matchesLessonType = filters.lessonType === ALL_VALUE || 
          student.teachers.some(t => 
            t.id === teacherData.teacher.id && 
            t.lessonType === filters.lessonType
          )

        const studentAccount = student.account || 'account1'
        const matchesAccount = filters.account === ALL_VALUE || 
          studentAccount === filters.account

        const matchesSubject = filters.subject === ALL_VALUE ||
          student.teachers.some(t =>
            t.id === teacherData.teacher.id &&
            t.subject === filters.subject
          )

        return matchesLessonType && 
          (onlyCashLessons ? filters.account === ALL_VALUE : matchesAccount) && 
          matchesSubject
      })

      return {
        teacher: teacherData.teacher,
        students: filteredStudents
      }
    })
  }

  const filteredTeachers = filterTeachers(teachers)

  return (
    <Wrapper>
      <SummaryPanel>
        <div>
          Zaznaczeni uczniowie: {selectedStudents.length}
        </div>
        <ButtonsContainer>
          <Button
            color='primary-outline'
            disabled={isNilOrEmpty(selectedStudents)}
            onClick={handleClearSelected}
          >
            Wyczyść
          </Button>
          <Button
            style={{ minWidth: '200px' }}
            color='primary-outline'
            disabled={isNilOrEmpty(selectedStudents)}
            onClick={handlePhoneNumbersModalOpen}
          >
            Pokaż numery telefonów
          </Button>
          <Button
            color='primary'
            disabled={isNilOrEmpty(selectedStudents)}
            onClick={handleRedirectToMessage}
          >
            Wyślij do wielu
          </Button>
        </ButtonsContainer>
      </SummaryPanel>
      <Content>
        <DebtorsFilters 
          onApplyFilters={handleApplyFilters} 
          initialFilters={filters}
          subjects={subjects} 
        />
        {filteredTeachers
          .filter(t => isNotNilOrEmpty(t.students))
          .map(({ teacher, students }) => (
            <DebtorsByTeacherTable
              key={teacher.id}
              teacher={teacher}
              students={students}
              selectedStudents={selectedStudents.map(s => s.id)}
              toggleStudentSelection={toggleStudentSelection}
              fetchDebtors={handleFetchDebtorsWithFilters}
            />
          ))}
      </Content>
      <Modal
        title='Numery zaznaczonych uczniów'
        open={phoneModalOpen}
        onClose={handlePhoneNumbersModalClose}
      >
        {selectedStudents.map(student => student.phoneNumber).join('; ')}
      </Modal>
    </Wrapper>
  )
}

export default Debtors

const SummaryPanel = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 299px;
  padding: 10px 30px;
  background: #fff;
  z-index: 10;
  box-shadow: ${({ theme }) => theme.shadows.main};
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const ButtonsContainer = styled.div`
  display: flex;
  gap: 10px;
  min-width: 500px;
`

const Wrapper = styled.div`
  padding-top: 75px;
`

const Content = styled.div`
  padding: 20px;
`
