import React, { useEffect, useState } from 'react'
import { Route, Router, Switch } from 'react-router-dom'
import PATHS from 'utils/paths'
import history from 'utils/history'
import { Helmet } from 'react-helmet'
import Login from 'pages/Login'
import styled from 'styled-components'
import Navigation from 'components/navigation/Navigation'
import Teachers from 'pages/Teachers'
import Students from 'pages/Students'
import Debtors from 'pages/Debtors'
import Finances from 'pages/Finances'
import Settings from 'pages/Settings'
import Dashboard from 'pages/Dashboard'
import Profile from 'pages/Profile'
import { useDispatch, useSelector } from 'react-redux'
import { clearCurrentUserRoutine, setCurrentUserRoutine } from 'modules/auth/ducks/actions'
import { findCurrentUser, logoutUser } from 'services/AuthService'
import TeacherDetails from 'pages/TeacherDetails'
import StudentDetails from 'pages/StudentDetails'
import Workers from 'pages/Workers'
import WorkerDetails from 'pages/WorkerDetails'
import Message from 'pages/Message'
import ArchivedTeachers from 'pages/ArchivedTeachers'
import { selectCurrentUser } from 'modules/auth/ducks/selectors'
import { isNotNilOrEmpty } from 'utils/ramda'
import CalendarSync from 'pages/CalendarSync'
import TeachersIncomes from 'pages/TeachersIncomes'
import PrivateRoute from 'components/PrivateRoute'
import { MobileContent, WebContent } from 'utils/rwd'
import MobileNavigation from 'components/navigation/MobileNavigation'
import MyStudents from 'pages/MyStudents'
import { getBlockData } from 'services/SettingsService'
import { propOr } from 'ramda'
import PanelTopicality from 'pages/PanelTopicality'
import { redirect } from 'utils/navigation'

const App = () => {
  const dispatch = useDispatch()
  const user = useSelector(selectCurrentUser)
  const [blockData, setBlockData] = useState({})
  const handleUserChange = user => {
    dispatch(setCurrentUserRoutine(user))
  }

  const handleClearUser = () => {
    dispatch(clearCurrentUserRoutine())
  }

  const handleLogout = () => {
    logoutUser(handleClearUser)
    redirect(PATHS.login)
  }

  useEffect(() => {
    findCurrentUser(handleUserChange)
    getBlockData()
      .then(resp => setBlockData(resp))
  }, [])

  const isPanelBlocked = propOr('', 'role', user) === 'teacher' && blockData.isBlocked

  return (
    <AppWrapper>
      <Router history={history}>
        <Helmet>
          <title>Smart School</title>
        </Helmet>
        {
          isPanelBlocked
            ? (
              <BlockedPanelWrapper>
                <BlockedPanelMessage>
                  {propOr('', 'message', blockData)}
                </BlockedPanelMessage>
                <LogoutButton onClick={handleLogout}>Wyloguj</LogoutButton>
              </BlockedPanelWrapper>
            )
            : (
              <>
                {
                  isNotNilOrEmpty(user) && (
                    <>
                      <WebContent>
                        <NavigationWrapper>
                          <Navigation />
                        </NavigationWrapper>
                      </WebContent>
                      <MobileContent>
                        <MobileNavigation />
                      </MobileContent>
                    </>
                  )
                }
                <ContentWrapper withPadding={isNotNilOrEmpty(user)}>
                  <Switch>
                    <Route exact path={PATHS.login} component={Login} />
                    <PrivateRoute
                      allowedRoles={['admin', 'worker']}
                      exact
                      path={PATHS.teachers}
                      component={Teachers}
                    />
                    <PrivateRoute
                      allowedRoles={['admin', 'worker']}
                      exact
                      path={PATHS.teacherDetails}
                      component={TeacherDetails}
                    />
                    <PrivateRoute
                      allowedRoles={['admin', 'worker']}
                      exact
                      path={PATHS.panelTopicality}
                      component={PanelTopicality}
                    />
                    <PrivateRoute
                      allowedRoles={['admin', 'worker']}
                      exact
                      path={PATHS.workers}
                      component={Workers}
                    />
                    <PrivateRoute
                      allowedRoles={['admin', 'worker']}
                      exact
                      path={PATHS.workerDetails}
                      component={WorkerDetails}
                    />
                    <PrivateRoute
                      allowedRoles={['admin', 'worker']}
                      exact
                      path={PATHS.students}
                      component={Students}
                    />
                    <PrivateRoute
                      allowedRoles={['admin', 'worker']}
                      exact
                      path={PATHS.studentDetails}
                      component={StudentDetails}
                    />
                    <PrivateRoute
                      allowedRoles={['admin', 'worker']}
                      exact
                      path={PATHS.debtors}
                      component={Debtors}
                    />
                    <PrivateRoute
                      allowedRoles={['admin']}
                      exact
                      path={PATHS.finances}
                      component={Finances}
                    />
                    <PrivateRoute
                      allowedRoles={['admin', 'worker']}
                      exact
                      path={PATHS.settings}
                      component={Settings}
                    />
                    <PrivateRoute
                      allowedRoles={['admin', 'teacher']}
                      exact
                      path={PATHS.dashboard}
                      component={Dashboard}
                    />
                    <PrivateRoute
                      allowedRoles={['admin', 'worker', 'teacher']}
                      exact
                      path={PATHS.profile}
                      component={Profile}
                    />
                    <PrivateRoute
                      allowedRoles={['admin', 'worker']}
                      exact
                      path={PATHS.message}
                      component={Message}
                    />
                    <PrivateRoute
                      allowedRoles={['admin', 'worker']}
                      exact
                      path={PATHS.archivedTeachers}
                      component={ArchivedTeachers}
                    />
                    <PrivateRoute
                      allowedRoles={['admin', 'worker']}
                      exact
                      path={PATHS.calendarSync}
                      component={CalendarSync}
                    />
                    <PrivateRoute
                      allowedRoles={['admin', 'worker']}
                      exact
                      path={PATHS.teachersIncomes}
                      component={TeachersIncomes}
                    />
                    <PrivateRoute
                      allowedRoles={['teacher']}
                      exact
                      path={PATHS.myStudents}
                      component={MyStudents}
                    />
                  </Switch>
                </ContentWrapper>
              </>

            )
        }
      </Router>
    </AppWrapper>
  )
}

export default App

const AppWrapper = styled.div`
  width: 100%;
  height: 100vh;
  overflow: hidden;
  display: flex;
`

const NavigationWrapper = styled.div`
  min-width: 300px;
  max-width: 300px;
  height: 100%;
  border-right: 1px solid ${({ theme }) => theme.colors.border};
  background-color: ${({ theme }) => theme.colors.primary.main};
`

const ContentWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: ${({ withPadding }) => withPadding ? '20px' : 0};
  overflow-y: auto;
  
  @media (max-width: 768px) {
    padding: ${({ withPadding }) => withPadding ? '40px 20px 20px' : 0};
  }
`

const BlockedPanelWrapper = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 30px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${({ theme }) => theme.colors.primary.main};
`

const LogoutButton = styled.div`
  text-align: center;
  color: #fff;
  width: fit-content;
  padding: 5px 15px;
  cursor: pointer;
  transition: all .3s;
  border-radius: 4px;

  &:hover {
    background-color: #fff;
    color: ${({ theme }) => theme.colors.primary.main};
  }
`

const BlockedPanelMessage = styled.div`
  color: #fff;
  font-size: 22px;
  text-align: center;
  max-width: 700px;
`
