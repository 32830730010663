import React, { useMemo } from 'react'
import { dissoc, propOr, reverse, values } from 'ramda'
import Table from 'components/Table'
import { isNotNilOrEmpty } from 'utils/ramda'
import moment from 'moment'
import styled from 'styled-components'
import EditCellTooltip from 'modules/teachersIncomes/components/EditCellTooltip'
import { redirect } from 'utils/navigation'
import { parseNumber } from 'utils/numbers'
import EditIncomesCommentModal from 'modules/teachersIncomes/components/EditIncomesCommentModal'

const TeachersIncomesTable = ({ teachers, dates }) => {
  const monthNames = useMemo(() => {
    return reverse(values(dates)).map(date => {
      return moment(date).format('MMMM')
    })
  }, [dates])

  const monthNumbers = useMemo(() => {
    return reverse(values(dates)).map(date => {
      return moment(date).format('MM')
    })
  }, [dates])

  return (
    <Table>
      <thead>
      <tr>
        <th>Nauczyciel</th>
        {
          monthNames && monthNames.map(month => (
            <MonthHeader>{month}</MonthHeader>
          ))
        }
      </tr>
      </thead>
      <tbody>
      {
        teachers.map(teacher => {
          const salaries = propOr([], 'salaries', teacher)
          const newValues = monthNumbers.map(key => salaries[key])

          return (
            <tr key={teacher.id}>
              <TeacherNameCell
                onClick={() => redirect(`/teachers/${teacher.id}`)}
              >
                {teacher.displayName}
                <EditIncomesCommentModal teacher={teacher} />
              </TeacherNameCell>
              {newValues.map(month => {
                return (
                  <MonthCell key={`month-cell-${teacher.id}-${Math.random() * 9999}`}>
                    {
                      isNotNilOrEmpty(month)
                        ? (
                          <EditCellTooltip item={month} teacher={dissoc('salaries', teacher)}>
                            {parseNumber(month.value) * -1}
                          </EditCellTooltip>
                        )
                        : '---'
                    }
                  </MonthCell>
                )
              })}
            </tr>
          )
        })
      }
      </tbody>
    </Table>
  )
}

export default TeachersIncomesTable

const MonthCell = styled.td`
  width: 120px;
  border: 1px solid ${({ theme }) => theme.colors.border};
  text-align: center !important;
  padding: 0 !important;
`

const MonthHeader = styled.th`
  width: 120px;
  text-align: center !important;
`

const TeacherNameCell = styled.td`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 15px;
`
