import { createRoutine } from 'redux-saga-routines'
import { put, takeLatest, fork, call } from '@redux-saga/core/effects'
import {
  fetchActiveTeachers, fetchArchivedTeachers,
  getSingleTeacher, getSingleTeacherDebitItems, getSingleTeacherExpenses,
  getSingleTeacherLessons, getSingleTeacherOtherAmounts,
  getTeachersList, getTeachersSalaries
} from 'services/TeachersService'

export const fetchTeachersListRoutine = createRoutine('FETCH_TEACHERS_LIST')
export const fetchArchivedTeachersListRoutine = createRoutine('FETCH_ARCHIVED_TEACHERS_LIST')
export const fetchSingleTeacherRoutine = createRoutine('FETCH_TEACHER')
export const fetchSingleTeacherLessonsRoutine = createRoutine('FETCH_TEACHER_LESSONS')
export const fetchSingleTeacherExpensesRoutine = createRoutine('FETCH_TEACHER_EXPENSES')
export const fetchSingleTeacherOtherAmountsRoutine = createRoutine('FETCH_TEACHER_OTHER')
export const clearSingleTeacherRoutine = createRoutine('CLEAR_TEACHER')
export const fetchSingleTeacherDebitItemsRoutine = createRoutine('FETCH_TEACHER_DEBIT_ITEMS')
export const fetchTeachersSalariesRoutine = createRoutine('FETCH_TEACHERS_SALARIES')

function * fetchTeachersList () {
  yield put(fetchTeachersListRoutine.request())
  try {
    const result = yield call(fetchActiveTeachers)
    yield put(fetchTeachersListRoutine.success(result))
  } catch (e) {
    yield put(fetchTeachersListRoutine.failure(e))
    console.error(e)
  }
}

function * fetchArchivedTeachersList () {
  yield put(fetchArchivedTeachersListRoutine.request())
  try {
    const result = yield call(fetchArchivedTeachers)
    yield put(fetchArchivedTeachersListRoutine.success(result))
  } catch (e) {
    yield put(fetchArchivedTeachersListRoutine.failure(e))
    console.error(e)
  }
}

function * fetchSingleTeacher ({ payload }) {
  yield put(fetchSingleTeacherRoutine.request())
  try {
    const result = yield call(getSingleTeacher, payload)
    yield put(fetchSingleTeacherRoutine.success(result))
  } catch (e) {
    yield put(fetchSingleTeacherRoutine.failure(e))
    console.error(e)
  }
}

function * fetchSingleTeacherLessons ({ payload }) {
  yield put(fetchSingleTeacherLessonsRoutine.request())
  try {
    const result = yield call(getSingleTeacherLessons, payload)
    yield put(fetchSingleTeacherLessonsRoutine.success(result))
  } catch (e) {
    yield put(fetchSingleTeacherLessonsRoutine.failure(e))
    console.error(e)
  }
}

function * fetchSingleTeacherExpenses ({ payload }) {
  yield put(fetchSingleTeacherExpensesRoutine.request())
  try {
    const result = yield call(getSingleTeacherExpenses, payload)
    yield put(fetchSingleTeacherExpensesRoutine.success(result))
  } catch (e) {
    yield put(fetchSingleTeacherExpensesRoutine.failure(e))
    console.error(e)
  }
}

function * fetchSingleTeacherOtherAmounts ({ payload }) {
  yield put(fetchSingleTeacherOtherAmountsRoutine.request())
  try {
    const result = yield call(getSingleTeacherOtherAmounts, payload)
    yield put(fetchSingleTeacherOtherAmountsRoutine.success(result))
  } catch (e) {
    yield put(fetchSingleTeacherOtherAmountsRoutine.failure(e))
    console.error(e)
  }
}

function * fetchTeachersSalaries ({ payload }) {
  yield put(fetchTeachersSalariesRoutine.request())
  try {
    const result = yield call(getTeachersSalaries, payload)
    yield put(fetchTeachersSalariesRoutine.success(result))
  } catch (e) {
    yield put(fetchTeachersSalariesRoutine.failure(e))
    console.error(e)
  }
}

function * fetchSingleTeacherDebitItems ({ payload }) {
  yield put(fetchSingleTeacherDebitItemsRoutine.request())
  try {
    const result = yield call(getSingleTeacherDebitItems, payload)
    yield put(fetchSingleTeacherDebitItemsRoutine.success(result))
  } catch (e) {
    yield put(fetchSingleTeacherDebitItemsRoutine.failure(e))
    console.error(e)
  }
}

function * clearSingleTeacher () {
  yield put(clearSingleTeacherRoutine.success())
}

export function * fetchTeachersListWatcher () {
  yield takeLatest(fetchTeachersListRoutine.TRIGGER, fetchTeachersList)
}

export function * fetchSingleTeacherWatcher () {
  yield takeLatest(fetchSingleTeacherRoutine.TRIGGER, fetchSingleTeacher)
}

export function * fetchSingleTeacherLessonsWatcher () {
  yield takeLatest(fetchSingleTeacherLessonsRoutine.TRIGGER, fetchSingleTeacherLessons)
}

export function * fetchSingleTeacherExpensesWatcher () {
  yield takeLatest(fetchSingleTeacherExpensesRoutine.TRIGGER, fetchSingleTeacherExpenses)
}

export function * fetchSingleTeacherOtherAmountsWatcher () {
  yield takeLatest(fetchSingleTeacherOtherAmountsRoutine.TRIGGER, fetchSingleTeacherOtherAmounts)
}

export function * fetchSingleTeacherDebitItemsWatcher () {
  yield takeLatest(fetchSingleTeacherDebitItemsRoutine.TRIGGER, fetchSingleTeacherDebitItems)
}

export function * clearSingleTeacherWatcher () {
  yield takeLatest(clearSingleTeacherRoutine.TRIGGER, clearSingleTeacher)
}

export function * fetchTeachersSalariesWatcher () {
  yield takeLatest(fetchTeachersSalariesRoutine.TRIGGER, fetchTeachersSalaries)
}

export function * fetchArchivedTeachersListWatcher () {
  yield takeLatest(fetchArchivedTeachersListRoutine.TRIGGER, fetchArchivedTeachersList)
}

export const teachersSagas = [
  fork(fetchTeachersListWatcher),
  fork(fetchSingleTeacherWatcher),
  fork(fetchSingleTeacherDebitItemsWatcher),
  fork(fetchSingleTeacherLessonsWatcher),
  fork(fetchSingleTeacherExpensesWatcher),
  fork(fetchSingleTeacherOtherAmountsWatcher),
  fork(clearSingleTeacherWatcher),
  fork(fetchArchivedTeachersListWatcher),
  fork(fetchTeachersSalariesWatcher)
]
