import React, { useEffect, useMemo } from 'react'
import styled from 'styled-components'
import { selectTeachersList, selectTeachersSalaries } from 'modules/teachers/ducks/selectors'
import { useDispatch, useSelector } from 'react-redux'
import {
  fetchTeachersListRoutine,
  fetchTeachersSalariesRoutine,
} from 'modules/teachers/ducks/actions'
import ExpandablePanel from 'components/ExpandablePanel'
import moment from 'moment'
import { DATE_FORMATS } from 'utils/date'
import TeachersIncomesTable from 'modules/teachersIncomes/components/TeachersIncomesTable'
import { isNotNilOrEmpty } from 'utils/ramda'

const formatTeacherName = name => {
  const [firstName, ...lastNameParts] = name.split(' ')
  const lastName = lastNameParts.join(' ')
  return `${lastName} ${firstName}`
}

const sortByLastName = (a, b) => {
  const [, ...aLastName] = a.name.split(' ')
  const [, ...bLastName] = b.name.split(' ')
  return aLastName.join(' ').localeCompare(bLastName.join(' '))
}

const TeachersIncomes = () => {
  const teachers = useSelector(selectTeachersList)
  const salaries = useSelector(selectTeachersSalaries)
  const dispatch = useDispatch()
  const dates = useMemo(() => ({
    current: moment(),
    prev: moment().subtract(1, 'month'),
    prev2: moment().subtract(2, 'month'),
    prev3: moment().subtract(3, 'month'),
    prev4: moment().subtract(4, 'month'),
    prev5: moment().subtract(5, 'month')
  }), [])

  const teachersWithSalaries = useMemo(() => {
    return teachers.map(teacher => {
      const teacherSalaries = salaries.filter(salary => salary.teacherId === teacher.id)
      let sorted = {}

      for (let key in dates) {
        const date = dates[key]
        const monthNumber = date.format('MM')

        sorted[monthNumber] = teacherSalaries.find(salary => {
          const firstDay = date.startOf('month').format(DATE_FORMATS.input)
          const lastDay = date.endOf('month').format(DATE_FORMATS.input)
          return moment(salary.date).isBetween(firstDay, lastDay, 'days', '[]')
        })
      }

      return {
        ...teacher,
        displayName: formatTeacherName(teacher.name),
        salaries: sorted
      }
    })
  }, [dates, teachers, salaries])

  useEffect(() => {
    dispatch(fetchTeachersListRoutine())
  }, [])

  useEffect(() => {
    dispatch(fetchTeachersSalariesRoutine({ dates }))
  }, [dates])

  const teachersGroups = [
    {
      label: 'Przelew',
      entries: teachersWithSalaries.filter(t => t.paymentType === 'transfer').sort(sortByLastName)
    },
    {
      label: 'Przekaz pocztowy',
      entries: teachersWithSalaries.filter(t => t.paymentType === 'postalOrder').sort((a, b) => a.name.localeCompare(b.name)).map(t => ({...t, displayName: t.name}))
    },
    {
      label: 'Inny typ rozliczenia',
      entries: teachersWithSalaries.filter(t => t.paymentType === 'other').sort((a, b) => a.name.localeCompare(b.name)).map(t => ({...t, displayName: t.name}))
    }
  ]

  return (
    <>
      <PageHeader>
        Wypłaty nauczycieli
      </PageHeader>
      <div>
        {
          teachersGroups.map(group => {
            return (
              <ExpandablePanel isStatic title={group.label}>
                {isNotNilOrEmpty(group.entries) && (
                  <TeachersIncomesTable dates={dates} teachers={group.entries} />
                )}
              </ExpandablePanel>
            )
          })
        }
      </div>
    </>
  )
}

export default TeachersIncomes

const PageHeader = styled.div`
  font-size: 24px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`
