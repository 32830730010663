import React, { useEffect, useMemo, useState } from 'react'
import AddLessonModal from 'modules/lessons/components/AddLessonModal'
import { useDispatch, useSelector } from 'react-redux'
import { selectCurrentUser } from 'modules/auth/ducks/selectors'
import styled from 'styled-components'
import moment from 'moment'
import { DATE_FORMATS } from 'utils/date'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import {
  fetchSingleTeacherExpensesRoutine,
  fetchSingleTeacherLessonsRoutine, fetchSingleTeacherOtherAmountsRoutine,
  fetchSingleTeacherRoutine
} from 'modules/teachers/ducks/actions'
import {
  selectCurrentTeacher,
  selectCurrentTeacherLessons,
  selectCurrentTeacherOtherAmounts
} from 'modules/teachers/ducks/selectors'
import ExpandablePanel from 'components/ExpandablePanel'
import { isNotNilOrEmpty } from 'utils/ramda'
import LessonsTable from 'modules/lessons/components/LessonsTable'
import ExpensesTable from 'modules/teacherDetails/components/ExpensesTable'
import { countLessonBalance } from 'utils/lessons'
import { MobileContent, WebContent } from 'utils/rwd'
import { parseNumber } from 'utils/numbers'
import { getSingleTeacherStudents } from 'services/TeachersService'
import Select from 'components/Select'
import { TEACHER_DEBIT_ITEM_TYPES } from 'utils/teacher'

const defaultDates = {
  dateFrom: moment().startOf('month').format(DATE_FORMATS.input),
  dateTo: moment().endOf('month').format(DATE_FORMATS.input)
}

const Dashboard = () => {
  const user = useSelector(selectCurrentUser)
  const lessons = useSelector(selectCurrentTeacherLessons)
  // const oldExpenses = useSelector(selectCurrentTeacherExpenses)
  const otherAmounts = useSelector(selectCurrentTeacherOtherAmounts)
  const teacher = useSelector(selectCurrentTeacher)
  const [currentDate, setCurrentDate] = useState(moment())
  const [dates, setDates] = useState(defaultDates)
  const dispatch = useDispatch()
  const [students, setStudents] = useState([])
  const [selectedStudent, setSelectedStudent] = useState('all')

  const expenses = [
    ...otherAmounts
      .filter(el => !['underpayment', 'salary'].includes(el.type))
      .filter(el => moment(el.date).isBetween(moment(dates.dateFrom), moment(dates.dateTo), '[]'))
      .map(el => {
        const value = el.type === 'overpayment' ? el.overpaymentValue : el.value
        return ({ title: TEACHER_DEBIT_ITEM_TYPES[el.type], date: el.date, value, comment: el.comment })
      })
  ]

  const getStudents = () => {
    getSingleTeacherStudents(teacher.id, setStudents)
  }

  const studentsOptions = useMemo(() => {
    const fromStudents = isNotNilOrEmpty(students)
      ? students.map(student => ({
        label: student.name,
        value: student.id
      }))
      : []
    return [
      { label: 'Wszyscy', value: 'all' },
      ...fromStudents
    ]
  }, [students])

  useEffect(() => {
    teacher.id && getStudents()
  }, [teacher])

  useEffect(() => {
    dispatch(fetchSingleTeacherRoutine({ id: user.id }))
  }, [user])

  const decrementMonth = () => {
    setCurrentDate(prev => moment(prev).subtract(1, 'month'))
  }

  const incrementMonth = () => {
    setCurrentDate(prev => moment(prev).add(1, 'month'))
  }

  useEffect(() => {
    setDates({
      dateFrom: currentDate.startOf('month').format(DATE_FORMATS.input),
      dateTo: currentDate.endOf('month').format(DATE_FORMATS.input)
    })
  }, [currentDate])

  const fetchData = () => {
    if (isNotNilOrEmpty(teacher)) {
      dispatch(fetchSingleTeacherExpensesRoutine({
        id: teacher.id,
        dateFrom: dates.dateFrom,
        dateTo: dates.dateTo,
      }))
      dispatch(fetchSingleTeacherOtherAmountsRoutine({
        id: teacher.id,
        dateFrom: dates.dateFrom,
        dateTo: dates.dateTo
      }))
      dispatch(fetchSingleTeacherLessonsRoutine({
        id: teacher.id,
        dateFrom: dates.dateFrom,
        dateTo: dates.dateTo,
      }))
    }
  }

  useEffect(() => {
    fetchData()
  }, [dates, teacher])

  useEffect(() => {
    fetchData()
  }, [])

  const lessonsBalance = lessons.reduce((acc, lesson) => acc + countLessonBalance(lesson), 0)
  const lessonsIncome = lessons.reduce((acc, { duration, teacherRate }) => acc + parseNumber(duration) * parseNumber(teacherRate), 0)
  const lessonsCount = lessons.length
  const lessonsDuration = lessons.reduce((acc, { duration }) => acc + parseNumber(duration), 0)
  const expensesBalance = expenses.reduce((acc, { value }) => acc + parseNumber(value), 0)
  const totalIncome = expensesBalance + lessonsIncome

  return (
    <>
      <PageHeader>
        <DateFilters>
          <ArrowBackIosNewIcon onClick={decrementMonth} />
          <MonthName>{currentDate.locale('pl').format('MMMM')} {currentDate.format('YYYY')}</MonthName>
          <ArrowForwardIosIcon onClick={incrementMonth} />
        </DateFilters>
        <WebContent>
          <Buttons>
            <AddLessonModal isDashboard teacher={teacher} customButton dates={dates} />
            {/*<AddTeacherExpenseModal teacher={teacher} customButton dates={dates} />*/}
          </Buttons>
        </WebContent>
      </PageHeader>
      <MobileContent>
        <MobileButtons>
          <AddLessonModal isDashboard teacher={teacher} customButton dates={dates} />
          {/*<AddTeacherExpenseModal teacher={teacher} customButton dates={dates} />*/}
        </MobileButtons>
      </MobileContent>
      <ExpandablePanel title='Podsumowanie' startOpen isStatic>
        {
          (isNotNilOrEmpty(expenses) || isNotNilOrEmpty(lessons)) && (
            <Summary>
              <div>Liczba lekcji: <strong>{lessonsCount}</strong></div>
              <div>Liczba godzin: <strong>{lessonsDuration}h</strong></div>
              <div>Bilans lekcji: <strong>{lessonsBalance}zł</strong></div>
              <div>Zarobek z lekcji: <strong>{lessonsIncome}zł</strong></div>
              <div>Pozostałe rozliczenia: <strong>{expensesBalance}zł</strong></div>
              <div>Wypłata: <strong>{totalIncome}zł</strong></div>
            </Summary>
          )
        }
        {
          isNotNilOrEmpty(lessons)
            ? (
              <>
                <SectionTitle>Lekcje</SectionTitle>
                <SelectStudentInputWrapper>
                  <Select
                    name='studentId'
                    options={studentsOptions}
                    value={selectedStudent}
                    label='Uczeń'
                    onChange={(_, value) => setSelectedStudent(value)}
                  />
                </SelectStudentInputWrapper>
                <LessonsTable isDashboard studentId={selectedStudent} />
              </>
            )
            : <div>Brak lekcji w tym miesiącu</div>
        }
        {
          isNotNilOrEmpty(expenses) &&(
            <>
              <SectionTitle>Pozostałe rozliczenia</SectionTitle>
              <ExpensesTable expenses={expenses} />
            </>
          )
        }
      </ExpandablePanel>
    </>
  )
}

export default Dashboard

const PageHeader = styled.div`
  font-size: 24px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 768px) {
    justify-content: center;
  }
`

const Buttons = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`

const MobileButtons = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 20px;
  
  & > div {
    flex: 1;
  }
`

const MonthName = styled.div`
  width: 200px;
  text-align: center;
`
const DateFilters = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 15px;
  text-transform: capitalize;
  font-size: 22px !important;
  
  svg {
    cursor: pointer;
  }

  @media (max-width: 768px) {
    margin: 0 auto;
  }
`

const SectionTitle = styled.div`
  margin: 30px 0 10px;
`

const Summary = styled.div`
  display: flex;
  gap: 20px;
  font-size: 14px;
  margin-top: 20px;
  justify-content: flex-end;
  
  strong {
    font-weight: bold;
  }
  
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 5px;
  }
`

const SelectStudentInputWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  
  & > div {
    width: 300px;

    @media (max-width: 768px) {
      width: 100% !important;
    }
  }
`
