import React, { useMemo, useState } from 'react'
import styled from 'styled-components'
import appLogo from 'assets/app-logo-alt.png'
import PATHS from 'utils/paths'
import { getCurrentPathname, redirect } from 'utils/navigation'
import { logoutUser } from 'services/AuthService'
import { useDispatch, useSelector } from 'react-redux'
import { clearCurrentUserRoutine } from 'modules/auth/ducks/actions'
import history from 'utils/history'
import AssignmentIndOutlinedIcon from '@mui/icons-material/AssignmentIndOutlined'
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined'
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined'
import SavingsOutlinedIcon from '@mui/icons-material/SavingsOutlined'
import CurrencyExchangeOutlinedIcon from '@mui/icons-material/CurrencyExchangeOutlined'
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined'
import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined'
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined'
import PowerSettingsNewOutlinedIcon from '@mui/icons-material/PowerSettingsNewOutlined'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import PointOfSaleOutlinedIcon from '@mui/icons-material/PointOfSaleOutlined'
import SentimentSatisfiedOutlinedIcon from '@mui/icons-material/SentimentSatisfiedOutlined'
import { selectCurrentUser } from 'modules/auth/ducks/selectors'
import { propOr } from 'ramda'

const NavigationContent = () => {
  const currentUser = useSelector(selectCurrentUser)

  const adminNavLinks = [
    { label: 'Nauczyciele', path: PATHS.teachers, icon: <GroupOutlinedIcon /> },
    { label: 'Pracownicy', path: PATHS.workers, icon: <AssignmentIndOutlinedIcon /> },
    { label: 'Uczniowie', path: PATHS.students, icon: <SchoolOutlinedIcon /> },
    { label: 'Dłużnicy', path: PATHS.debtors, icon: <CurrencyExchangeOutlinedIcon /> },
    { label: 'Wypłaty nauczycieli', path: PATHS.teachersIncomes, icon: <PointOfSaleOutlinedIcon /> },
    { label: 'Aktualność panelu', path: PATHS.panelTopicality, icon: <SentimentSatisfiedOutlinedIcon /> },
    { label: 'Import lekcji', path: PATHS.calendarSync, icon: <CalendarMonthIcon /> },
    { label: 'Finanse', path: PATHS.finances, icon: <SavingsOutlinedIcon /> },
    { label: 'Archiwum nauczycieli', path: PATHS.archivedTeachers, icon: <Inventory2OutlinedIcon />},
    // { label: 'Archiwum uczniów', path: PATHS.archivedStudents, icon: <Inventory2OutlinedIcon /> },
    { label: 'Profil użytkownika', path: PATHS.profile, icon: <PermIdentityOutlinedIcon /> },
    { label: 'Ustawienia', path: PATHS.settings, icon: <SettingsOutlinedIcon /> }
  ]

  const workerNavLinks = [
    { label: 'Nauczyciele', path: PATHS.teachers, icon: <GroupOutlinedIcon /> },
    { label: 'Pracownicy', path: PATHS.workers, icon: <AssignmentIndOutlinedIcon /> },
    { label: 'Uczniowie', path: PATHS.students, icon: <SchoolOutlinedIcon /> },
    { label: 'Dłużnicy', path: PATHS.debtors, icon: <CurrencyExchangeOutlinedIcon /> },
    { label: 'Wypłaty nauczycieli', path: PATHS.teachersIncomes, icon: <PointOfSaleOutlinedIcon /> },
    { label: 'Aktualność panelu', path: PATHS.panelTopicality, icon: <SentimentSatisfiedOutlinedIcon /> },
    { label: 'Import lekcji', path: PATHS.calendarSync, icon: <CalendarMonthIcon /> },
    { label: 'Archiwum nauczycieli', path: PATHS.archivedTeachers, icon: <Inventory2OutlinedIcon />},
    // { label: 'Archiwum uczniów', path: PATHS.archivedStudents, icon: <Inventory2OutlinedIcon /> },
    { label: 'Profil użytkownika', path: PATHS.profile, icon: <PermIdentityOutlinedIcon /> },
    { label: 'Ustawienia', path: PATHS.settings, icon: <SettingsOutlinedIcon /> }
  ]

  const teacherNavLinks = [
    { label: 'Podsumowanie', path: PATHS.dashboard, icon: <CalendarMonthIcon /> },
    { label: 'Uczniowie', path: PATHS.myStudents, icon: <AssignmentIndOutlinedIcon /> },
    { label: 'Profil użytkownika', path: PATHS.profile, icon: <PermIdentityOutlinedIcon /> }
  ]

  const [pathname, setPathname] = useState(getCurrentPathname())

  const dispatch = useDispatch()

  const handleClearUser = () => {
    dispatch(clearCurrentUserRoutine())
  }

  history.listen(({ pathname }) => {
    setPathname(pathname)
  })

  const handleLogout = () => {
    logoutUser(handleClearUser)
    setPathname(getCurrentPathname())
  }

  const handleLinkClick = path => () => {
    redirect(path)
    setPathname(path)
  }

  const getNavLinks = useMemo(() => {
    const userRole = propOr('', 'role', currentUser)
    let links = []
    switch (userRole) {
      case 'admin':
        links = adminNavLinks
        break
      case 'worker':
        links = workerNavLinks
        break
      case 'teacher':
        links = teacherNavLinks
        break
      default:
        break
    }

    return links.map(link => (
      <Link
        active={pathname.includes(link.path)}
        onClick={handleLinkClick(link.path)}
        key={link.path}
      >
        {link.icon}
        {link.label}
      </Link>
    ))
  }, [pathname, currentUser])

  return (
    <Wrapper>
      <div>
        <LogoWrapper>
          <img src={appLogo} alt='Smart School' />
        </LogoWrapper>
        <MenuWrapper>
          {getNavLinks}
        </MenuWrapper>
      </div>
      <div>
        <Link onClick={handleLogout}>
          <PowerSettingsNewOutlinedIcon />
          Wyloguj
        </Link>
      </div>
    </Wrapper>
  )
}

export default NavigationContent

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const LogoWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  
  img {
    max-height: 80px;
  }
`

const Link = styled.div`
  color: ${({ active, theme }) => active ? theme.colors.primary.main : '#fff'};
  padding: 5px 10px;
  cursor: pointer;
  font-size: 16px;
  margin-bottom: 3px;
  transition: all .3s;
  border-radius: 4px;
  display: flex;
  align-items: center;
  gap: 10px;
  background-color: ${({ active, theme }) => active ? theme.colors.secondary.main : 'transparent'};
  
  &:hover {
    background-color: ${({ theme }) => theme.colors.secondary.main};
    color: ${({ theme }) => theme.colors.primary.main};
  }
`

const MenuWrapper = styled.div`
  margin-top: 20px;
`
