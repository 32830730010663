import React, { useMemo } from 'react'
import { pathOr, propOr } from 'ramda'
import moment from 'moment'
import { isCashLesson } from 'utils/lessons'
import styled, { css } from 'styled-components'
import ExpandablePanel from 'components/ExpandablePanel'
import Checkbox from 'components/Checkbox'
import { Tooltip } from '@mui/material'
import { isNilOrEmpty } from 'utils/ramda'
import ForwardToInboxOutlinedIcon from '@mui/icons-material/ForwardToInboxOutlined'
import PATHS from 'utils/paths'
import { redirect } from 'utils/navigation'
import AddDebtorsPaymentModal from 'modules/debtors/components/AddDebtorsPaymentModal'
import EditStudentModal from 'modules/students/components/EditStudentModal'
import { parseNumber } from 'utils/numbers'

const DebtorsByTeacherTable = ({ fetchDebtors, teacher, students, toggleStudentSelection, selectedStudents }) => {
  const dates = {
    currentMonth: moment().format('MMMM'),
    prevMonth: moment().subtract(1, 'month').format('MMMM'),
    prev2Month: moment().subtract(2, 'month').format('MMMM'),
  }

  const debitDetails = useMemo(() => {
    return students.map(student => {
      const studentLessons = propOr([], 'lessons', student)
      const lessonsByMonth = {
        afterCurrent: studentLessons.filter(lesson => moment(lesson.date).isAfter(moment().endOf('month'), 'day')),
        currentMonth: studentLessons.filter(lesson => moment(lesson.date).isBetween(moment().startOf('month'), moment().endOf('month'), 'day', '[]')),
        prevMonth: studentLessons.filter(lesson => moment(lesson.date).isBetween(moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month'), 'day', '[]')),
        prev2Month: studentLessons.filter(lesson => moment(lesson.date).isBetween(moment().subtract(2, 'month').startOf('month'), moment().subtract(2, 'month').endOf('month'), 'day', '[]'))
      }

      const lessonBalances = {
        afterCurrent: lessonsByMonth.afterCurrent.reduce((acc, lesson) => {
          if (isCashLesson(lesson.lessonType)) {
            return acc
          } else {
            return acc - (parseNumber(lesson.duration) * parseNumber(lesson.studentRate))
          }
        }, 0),
        currentMonth: lessonsByMonth.currentMonth.reduce((acc, lesson) => {
          if (isCashLesson(lesson.lessonType)) {
            return acc
          } else {
            return acc - (parseNumber(lesson.duration) * parseNumber(lesson.studentRate))
          }
        }, 0),
        prevMonth: lessonsByMonth.prevMonth.reduce((acc, lesson) => {
          if (isCashLesson(lesson.lessonType)) {
            return acc
          } else {
            return acc - (parseNumber(lesson.duration) * parseNumber(lesson.studentRate))
          }
        }, 0),
        prev2Month: lessonsByMonth.prev2Month.reduce((acc, lesson) => {
          if (isCashLesson(lesson.lessonType)) {
            return acc
          } else {
            return acc - (parseNumber(lesson.duration) * parseNumber(lesson.studentRate))
          }
        }, 0)
      }

      const currentBalance = () => {
        let balance = parseNumber(student.balance) - lessonBalances.afterCurrent
        let currentMonth = lessonBalances.currentMonth
        let prevMonth = lessonBalances.prevMonth
        let prev2Month = lessonBalances.prev2Month

        if (lessonBalances.currentMonth > balance) {
          currentMonth = lessonBalances.currentMonth
          balance -= lessonBalances.currentMonth
        } else if (lessonBalances.currentMonth === balance) {
          return {
            currentMonth: balance,
            prevMonth: 0,
            prev2Month: 0
          }
        } else {
          return {
            currentMonth: balance,
            prevMonth: 0,
            prev2Month: 0
          }
        }

        if (lessonBalances.prevMonth > balance) {
          prevMonth = lessonBalances.prevMonth
          balance -= lessonBalances.prevMonth
        } else if (lessonBalances.prevMonth === balance) {
          return {
            currentMonth,
            prevMonth: balance,
            prev2Month: 0
          }
        } else {
          return {
            currentMonth,
            prevMonth: balance,
            prev2Month: 0
          }
        }

        if (lessonBalances.prev2Month > balance) {
          prev2Month = lessonBalances.prev2Month
          balance -= lessonBalances.prev2Month
        } else if (lessonBalances.prev2Month === balance) {
          return {
            currentMonth,
            prevMonth,
            prev2Month: balance
          }
        } else {
          return {
            currentMonth,
            prevMonth,
            prev2Month: balance
          }
        }

        return {
          currentMonth,
          prevMonth,
          prev2Month
        }
      }

      return {
        ...student,
        hasLessonsInPrevMonth: lessonBalances.prevMonth < 0,
        balances: currentBalance()
      }
    })
  }, [students])

  const handleEditCallback = () => {
    setTimeout(() => {
      fetchDebtors()
    }, 100)
  }

  return (
    <ExpandablePanel startOpen title={teacher.name}>
      <Table key={`${teacher.id}-table`}>
        <thead>
        <tr>
          <CheckboxCell />
          <th>Uczeń</th>
          <th>{dates.prev2Month}</th>
          <th>{dates.prevMonth}</th>
          <th>{dates.currentMonth}</th>
          <th>Razem</th>
          <th>Ostatnia wiadomość</th>
          <th>Wysłany w tym miesiącu</th>
          <th />
        </tr>
        </thead>
        <tbody>
        {debitDetails.map(student => {
          const todayMonth = moment().format('MM')
          const todayYear = moment().format('YYYY')
          const lastMessageMonth = propOr('', 'lastMessageDate', student).slice(3,5)
          const lastMessageYear = propOr('', 'lastMessageDate', student).slice(6,10)
          const messageSentInThisMonth = (todayMonth === lastMessageMonth) && (todayYear === lastMessageYear)

          return (
            <tr
              key={student.id}
              onClick={() => redirect(`/students/${student.id}`)}
              data-id={student.id}
            >
              <CheckboxTableCell onClick={e => e.stopPropagation()}>
                {/*{!student.hasLessonsInPrevMonth && (*/}
                {/*  <Tooltip title='Brak lekcji w poprzednim miesiącu'>*/}
                {/*    <NoLessonsDot>•</NoLessonsDot>*/}
                {/*  </Tooltip>*/}
                {/*)}*/}
                <Checkbox
                  disabled={isNilOrEmpty(student.email) || isNilOrEmpty(student.phoneNumber)}
                  value={selectedStudents.includes(student.id)}
                  onChange={toggleStudentSelection(student)}
                />
              </CheckboxTableCell>
              <td>{student.name}</td>
              <td>{pathOr('---', ['balances', 'prev2Month'], student)}</td>
              <td>{pathOr('---', ['balances', 'prevMonth'], student)}</td>
              <td>{pathOr('---', ['balances', 'currentMonth'], student)}</td>
              <td>{student.balance}</td>
              <td>{propOr('---', 'lastMessageDate', student)}</td>
              <td>
                {messageSentInThisMonth
                  ? (
                    <Tooltip title={propOr('---', 'lastMessage', student)}>
                      <CheckIcon>✔</CheckIcon>
                    </Tooltip>
                  )
                  : (
                    <Tooltip title={propOr('---', 'lastMessage', student)}>
                      <CloseIcon>✘</CloseIcon>
                    </Tooltip>
                  )
                }
              </td>
              <Actions onClick={e => e.stopPropagation()}>
                <MailIcon
                  disabled={isNilOrEmpty(student.email) || isNilOrEmpty(student.phoneNumber)}
                  onClick={e => {
                    e.stopPropagation()
                    return isNilOrEmpty(student.email)
                      ? {}
                      : redirect(PATHS.message, [student])
                  }}
                />
                <EditStudentModal
                  student={student}
                  isSingleStudentView={false}
                  isTeacherView={false}
                  isDebtorsView={true}
                  actionCallback={handleEditCallback}
                />
                <AddDebtorsPaymentModal student={student} fetchDebtors={fetchDebtors} />
              </Actions>
            </tr>
          )
        })}
        </tbody>
      </Table>
    </ExpandablePanel>

  )
}

export default DebtorsByTeacherTable

const Table = styled.table`
  width: 100%;
  margin-top: 20px;
  font-size: 12px;
  position: relative;
  
  th, td {
    text-align: left;
    padding: 5px;
  }
  
  tr:not(:last-of-type) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.border};
  }
  
  th {
    border-bottom: 1px solid black;
    text-transform: uppercase;
  }
  
  tr {
    transition: all .3s;
    cursor: pointer;

    &:hover {
      background-color: ${({ theme }) => theme.colors.lightGrey};
    }
  }
`

const CheckboxCell = styled.th`
  width: 30px;
`

const CheckboxTableCell = styled.td`
  position: relative;
`

const NoLessonsDot = styled.span`
  position: absolute;
  left: -10px;
  font-size: 20px;
  top: 2px;
  color: red;
  cursor: help;
`

const CheckIcon = styled.div`
  color: green;
`

const CloseIcon = styled.div`
  color: red;
`

const MailIcon = styled(ForwardToInboxOutlinedIcon)`
  transition: all .3s;
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.colors.secondary.main};
  }
  
  ${({ disabled }) => disabled && css`
    color: ${({ theme }) => theme.colors.disabled};
    cursor: default;
    
    &:hover {
      color: ${({ theme }) => theme.colors.disabled};
    } 
  `}
`

const Actions = styled.div`
  text-align: right;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
`
