import { db, secondaryApp } from 'firestoreConfig'
import { dissoc, pathOr, propOr, reverse } from 'ramda'
import moment from 'moment'
import { DATE_FORMATS, formatDate } from 'utils/date'
import { updatePreviousTeacherDebitItems } from 'services/LessonsService'
import { toast } from 'react-hot-toast'
import { isNotNilOrEmpty } from 'utils/ramda'
import { parseNumber } from 'utils/numbers'
import { fetchActiveStudents } from 'services/StudentsService'

export const addTeacher = ({ values, callback }) => {
  return secondaryApp
    .auth()
    .createUserWithEmailAndPassword(values.email, values.password)
    .then(async resp => {
      const uid = resp.user.uid
      const docRef = db.collection('teachers').doc(uid)

      const valuesToSet = {
        ...dissoc('password', values),
        id: uid
      }

      await docRef.set(valuesToSet)
      typeof callback === 'function' && callback()
    })
    .catch(error => {
      if (error.code === 'auth/email-already-in-use') {
        toast.error('Podany adres email jest już w użyciu')
      }
      console.error(error.message)
    })
}

export const fetchActiveTeachers = () => {
  let teachers = []
  return db
    .collection('teachers')
    .where('removed', '!=', true)
    .get()
    .then(querySnapshot => {
      querySnapshot.forEach(doc => {
        teachers = [...teachers, doc.data()]
      })
    })
    .then(() => {
      return teachers
    })
    .catch(error => {
      console.log('Error getting document:', error)
      return []
    })
}

export const fetchArchivedTeachers = () => {
  let teachers = []
  return db
    .collection('teachers')
    .where('removed', '==', true)
    .get()
    .then(querySnapshot => {
      querySnapshot.forEach(doc => {
        teachers = [...teachers, doc.data()]
      })
    })
    .then(() => {
      return teachers
    })
    .catch(error => {
      console.log('Error getting document:', error)
      return []
    })
}

export const getTeachersList = () => {
  return db
    .collection('lists')
    .doc('teachers')
    .get()
    .then(async doc => {
      if (doc.exists) {
        const data = doc.data()
        return data.list
      }
    })
    .catch(error => {
      console.error('Error getting document:', error)
      return []
    })
}

export const getSingleTeacher = ({ id, callback }) => {
  return db
    .collection('teachers')
    .doc(id)
    .get()
    .then(async doc => {
      if (doc.exists) {
        const data = await doc.data()
        typeof callback === 'function' && callback(data)
        return data
      }
    })
    .catch(error => {
      console.error('Error getting document:', error)
    })
}

export const getSingleTeacherLessons = async ({ id, dateFrom, dateTo }) => {
  const snapshot = await db
    .collection('lessons')
    .where('teacher.id', '==', id)
    .where('date', '>=', dateFrom)
    .where('date', '<=', `${dateTo}T23:59`)
    .get()
  if (snapshot.empty) {
    return []
  } else {
    let data = []
    snapshot.forEach(doc => {
      data = [...data, doc.data()]
    })
    return data
  }
}

export const getSingleTeacherExpenses = async ({ id, dateFrom, dateTo }) => {
  const snapshot = await db
    .collection('expenses')
    .where('teacherId', '==', id)
    .where('date', '>=', dateFrom)
    .where('date', '<=', `${dateTo}T23:59`)
    .get()
  if (snapshot.empty) {
    return []
  } else {
    let data = []
    snapshot.forEach(doc => {
      data = [...data, doc.data()]
    })
    return data
  }
}

export const getSingleTeacherOtherAmounts = async ({ id, dateFrom, dateTo }) => {
  const snapshot = await db
    .collection('teacherDebit')
    .where('teacherId', '==', id)
    .where('type', '!=', 'lesson')
    .get()

  if (snapshot.empty) {
    return []
  } else {
    let data = []
    snapshot.forEach(doc => {
      data = [...data, doc.data()]
    })
    return data
  }
}

export const getSingleTeacherDebitItems = async ({ id, dateFrom, dateTo }) => {
  const snapshot = await db
    .collection('teacherDebit')
    .where('teacherId', '==', id)
    .where('date', '>=', dateFrom)
    .where('date', '<=', `${dateTo}T23:59`)
    .get()
  if (snapshot.empty) {
    return []
  } else {
    let data = []
    snapshot.forEach(doc => {
      data = [...data, doc.data()]
    })
    return data
  }
}

export const getSingleTeacherRates = (id, callback) => {
  return db
    .collection('teachers')
    .doc(id)
    .get()
    .then(async doc => {
      if (doc.exists) {
        const data = await doc.data()
        callback(propOr([], 'rates', data))
        return data
      }
    })
    .catch(error => {
      console.error('Error getting document:', error)
    })
}

export const getSingleTeacherStudents = async (id, callback) => {
  const list = await fetchActiveStudents()
  const result = list.filter(student => {
    const teachers = propOr([], 'teachers', student)
    return teachers.some(teacher => teacher.id === id) && !student.removed
  })
  callback(result)
  return result.sort((a, b) => a.name.localeCompare(b.name))
}

export const updateTeacher = ({ teacher, callback }) => {
  const objectRef = db.collection('teachers').doc(teacher.id)
  return objectRef.update(dissoc('id', teacher))
    .then(async () => {
      const data = await objectRef.get()
      typeof callback === 'function' && callback()
      return data.data()
    })
    .catch(function (error) {
      console.error('Error updating document: ', error)
    })
}

export const removeTeacher = ({ teacher, callback }) => {
  updateTeacher({
    teacher: {
      ...teacher,
      removed: true
    },
    callback
  })
}

export const restoreTeacher = ({ teacher, callback }) => {
  updateTeacher({
    teacher: {
      ...teacher,
      removed: false
    },
    callback
  })
}

export const addTeacherSalary = async ({ values, callback }) => {
  const { teacherDebitPayload, teacherPayload } = values
  const teacherDebitRef = db.collection('teacherDebit').doc(teacherDebitPayload.id)
  const teacherRef = db.collection('teachers').doc(teacherPayload.id)
  // const { date } = teacherDebitPayload
  // const thisMonthSnapshot = await db
  //   .collection('teacherDebit')
  //   .where('teacherId', '==', teacherPayload.id)
  //   .where('type', '==', 'salary')
  //   .where('date', '<', moment(date).endOf('month').format(DATE_FORMATS.input))
  //   .where('date', '>', moment(date).startOf('month').format(DATE_FORMATS.input))
  //   .get()
  //
  // let thisMonthItems = []
  // if (!thisMonthSnapshot.empty) {
  //   thisMonthSnapshot.forEach(doc => {
  //     const data = doc.data()
  //     thisMonthItems.push(data)
  //   })
  // }

  const prevSnapshot = await db
    .collection('teacherDebit')
    .where('teacherId', '==', teacherPayload.id)
    .where('date', '<', teacherDebitPayload.date)
    .where('date', '>', moment(teacherDebitPayload.date).subtract(6, 'month').format(DATE_FORMATS.inputWithTime))
    .get()

  const prepareTeacherDebitItems = new Promise(resolve => {
    let debitsData = []
    let index = 0
    if (!prevSnapshot.empty) {
      prevSnapshot.forEach(doc => {
        debitsData = [...debitsData, doc.data()]
        if (index === prevSnapshot.docs.length - 1) {
          resolve(debitsData)
        }
        index++
      })
    } else {
      return resolve([])
    }
  })

  prepareTeacherDebitItems
    .then(async teacherDebitItems => {
      const sortedTeacherDebitItems = reverse(teacherDebitItems.sort((a, b) => a.date - b.date))
      const lastBalance = parseNumber(pathOr(0, [0, 'balanceAfter'], sortedTeacherDebitItems))
      const batch = db.batch()

      const fullDebitPayload = {
        ...teacherDebitPayload,
        balanceAfter: parseNumber(lastBalance) + parseNumber(teacherDebitPayload.value)
      }

      batch.set(teacherDebitRef, fullDebitPayload)
      batch.update(teacherRef, teacherPayload)

      batch.commit()
        .then(() => {
          updatePreviousTeacherDebitItems({
            debit: fullDebitPayload,
            callback
          })
          toast.success('Dodano nową wypłatę')
          typeof callback === 'function' && callback()
        })
        .catch((error) => {
          toast.error('Wystąpił błąd podczas dodawania nowej wypłaty')
          console.error("Transakcja nie powiodła się: ", error)
        })
    })

}

export const removeTeacherSalary = async ({ values, callback }) => {
  const { teacherDebitPayload, teacherPayload } = values
  const teacherDebitRef = db.collection('teacherDebit').doc(teacherDebitPayload.id)
  const teacherRef = db.collection('teachers').doc(teacherPayload.id)
  const batch = db.batch()

  batch.delete(teacherDebitRef)
  batch.update(teacherRef, teacherPayload)

  batch.commit()
    .then(() => {
      updatePreviousTeacherDebitItems({
        debit: teacherDebitPayload,
        lessonDifference: parseNumber(teacherDebitPayload.value) * -1,
        callback
      })
      toast.success('Usunięto wypłatę')
      typeof callback === 'function' && callback()
    })
    .catch((error) => {
      toast.error('Wystąpił błąd podczas usuwania wypłaty')
      console.error("Transakcja nie powiodła się: ", error)
    })
}

export const updateTeacherSalary = async ({ values, callback }) => {
  const { teacherDebitPayload, teacherPayload, difference } = values
  const teacherDebitRef = db.collection('teacherDebit').doc(teacherDebitPayload.id)
  const teacherRef = db.collection('teachers').doc(teacherPayload.id)
  const batch = db.batch()

  batch.update(teacherDebitRef, teacherDebitPayload)
  batch.update(teacherRef, teacherPayload)

  batch.commit()
    .then(() => {
      updatePreviousTeacherDebitItems({
        debit: teacherDebitPayload,
        lessonDifference: difference,
        callback
      })
      toast.success('Dodano nową wypłatę')
      typeof callback === 'function' && callback()
    })
    .catch((error) => {
      toast.error('Wystąpił błąd podczas dodawania nowej wypłaty')
      console.error("Transakcja nie powiodła się: ", error)
    })
}

export const getTeachersSalaries = async ({ dates }) => {
  const { current, prev5 } = dates
  const dateFrom = `${prev5.startOf('month').format(DATE_FORMATS.input)}T00:00`
  const dateTo = `${current.endOf('month').format(DATE_FORMATS.input)}T23:59`

  const snapshot = await db
    .collection('teacherDebit')
    .where('type', '==', 'salary')
    .where('date', '>=', dateFrom)
    .where('date', '<=', `${dateTo}`)
    .get()
  if (snapshot.empty) {
    return []
  } else {
    let data = []
    snapshot.forEach(doc => {
      const itemData = doc.data()
      data = [...data, itemData]
    })
    return data
  }
}

export const updateTeacherDebitItem = async ({ values, callback }) => {
  const itemRef = await db.collection('teacherDebit').doc(values.id)
  itemRef
    .update(values)
    .then(() => {
      callback && callback()
      toast.success('Zapisano zmiany')
    })
}

export const checkTeachersCorrectness = async () => {
  const dateFrom = moment().subtract(3, 'month').format(DATE_FORMATS.input)
  let errors = []

  const debits = await db
    .collection('teacherDebit')
    .where('date', '>=', dateFrom)
    .get()

  let debitsData = []
  debits.forEach(doc => {
    debitsData = [...debitsData, doc.data()]
  })

  const list = await fetchActiveTeachers()

  list.forEach(teacher => {
    const teacherDebits = debitsData.filter(debit => debit.teacherId === teacher.id)
    const sorted = reverse(teacherDebits.sort((a, b) => a.date - b.date))
    const lastBalance = parseNumber(pathOr(0, [0, 'balanceAfter'], sorted))

    if (parseNumber(lastBalance) !== parseNumber(teacher.balance)) {
      errors = [...errors, {
        name: teacher.name,
        id: teacher.id,
        lastItemBalance: lastBalance,
        teacherBalance: teacher.balance,
        sorted: sorted.map(item => item.balanceAfter)
      }]
    }
  })
  return errors
}

export const checkStudentsCorrectness = async () => {
  const dateFrom = moment().subtract(3, 'month').format(DATE_FORMATS.input)
  let errors = []

  const debits = await db
    .collection('debit')
    .where('date', '>=', dateFrom)
    .get()

  const prepareDebitItems = new Promise(resolve => {
    let debitsData = []
    let index = 0
    debits.forEach(doc => {
      debitsData = [...debitsData, doc.data()]
      if (index === debits.docs.length - 1) {
        resolve(debitsData)
      }
      index++
    })
  })

  const list = await fetchActiveStudents()

  return prepareDebitItems
    .then(debitsData => {
      list.filter(s => !s.removed).forEach(student => {
        const studentDebits = debitsData.filter(debit => debit.studentId === student.id)
        const sorted = reverse(studentDebits.sort((a, b) => a.date - b.date))
        const lastBalance = pathOr(0, [0, 'balanceAfter'], sorted)

        if (parseNumber(lastBalance) !== parseNumber(student.balance)) {
          errors = [...errors, {
            name: student.name,
            id: student.id,
            lastItemBalance: lastBalance,
            studentBalance: student.balance,
            sorted: sorted.map(item => item.balanceAfter)
          }]
        }
      })
      return errors
    })
}

export const getTeacherLastLessonDates = async () => {
  const dateFrom = moment().subtract(2, 'month').format(DATE_FORMATS.input)

  const list = await fetchActiveTeachers()

  return list.map(teacher => {
    return db
      .collection('lessons')
      .where('date', '>=', dateFrom)
      .where('teacher.id', '==', teacher.id)
      .get()
      .then(lessonsSnapshot => {
        if (lessonsSnapshot.empty) {
          return {
            name: teacher.name,
            date: '---'
          }
        } else {
          let lessons = []
          lessonsSnapshot.forEach(doc => {
            lessons = [...lessons, doc.data()]
          })
          const sortedLessons = reverse(lessons.sort((a, b) => a.date - b.date))
          const lastLesson = sortedLessons[0]
          return {
            name: teacher.name,
            date: formatDate(lastLesson.date, DATE_FORMATS.input)
          }
        }
      })
  })
}
