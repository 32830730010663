import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import Select from 'components/Select'
import Button from 'components/Button'
import { LESSON_TYPES_SELECT_OPTIONS } from 'utils/lessons'
import { STUDENT_ACCOUNTS } from 'utils/student'

const ALL_VALUE = 'all'

const DebtorsFilters = ({ onApplyFilters, initialFilters, subjects }) => {
  const [filters, setFilters] = useState(initialFilters || {
    lessonType: ALL_VALUE,
    account: ALL_VALUE,
    subject: ALL_VALUE
  })

  useEffect(() => {
    if (initialFilters) {
      setFilters(initialFilters)
    }
  }, [initialFilters])

  const handleFilterChange = (name, value) => {
    setFilters(prev => ({
      ...prev,
      [name]: value
    }))
  }

  const handleApplyFilters = () => {
    onApplyFilters(filters)
  }

  const lessonTypeOptions = [
    { label: 'Wszyscy', value: ALL_VALUE },
    ...LESSON_TYPES_SELECT_OPTIONS
  ]

  const accountOptions = [
    { label: 'Wszyscy', value: ALL_VALUE },
    ...Object.entries(STUDENT_ACCOUNTS).map(([value, label]) => ({ value, label }))
  ]

  const subjectOptions = [
    { label: 'Wszyscy', value: ALL_VALUE },
    ...subjects.map(subject => ({ value: subject.name, label: subject.name }))
  ]

  return (
    <Wrapper>
      <FiltersContainer>
        <Select
          label='Typ lekcji'
          name='lessonType'
          onChange={handleFilterChange}
          value={filters.lessonType}
          options={lessonTypeOptions}
        />
        <Select
          label='Konto do wpłat'
          name='account'
          onChange={handleFilterChange}
          value={filters.account}
          options={accountOptions}
        />
        <Select
          label='Przedmiot'
          name='subject'
          onChange={handleFilterChange}
          value={filters.subject}
          options={subjectOptions}
        />
      </FiltersContainer>
      <ButtonWrapper>
        <Button
          color='primary'
          onClick={handleApplyFilters}
        >
          Zastosuj
        </Button>
      </ButtonWrapper>
    </Wrapper>
  )
}

export default DebtorsFilters

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 30px;
`

const FiltersContainer = styled.div`
  display: flex;
  gap: 20px;
  flex: 1;
` 

const ButtonWrapper = styled.div`
  width: 100px;
  padding-bottom: 10px;
`